<template>
    <b-modal :title="$t('material.stock_taking.acquire_scanned_items')" v-model="modalActive" no-close-on-backdrop size="lg">
        <template #default>
            <CustomTable ref="scanned_items_table" v-if="!edit" class="mt-1" :fields="fields" :items="scannedItems" @edit="editStock">
                <template #actions="{item}">
                    <b-button class="mr-1 m-0 button" variant="danger" size="sm" @click="deleteItem(item.id)" style="padding: 3px 5px;">
                        <span style="font-size: 10px">
                            {{ $t('general.delete') }}
                        </span>
                    </b-button>
                </template>
            </CustomTable>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="acquire" :disabled="edit" >
                <span>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.acquire') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BModal} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import {getMoneyTypes, getPieceTypes, getVolumeTypes, getWeightTypes} from '@/libs/constants/ingredients'
    import CustomTable from '@/components/CustomTable.vue'

    export default {
        components: {
            CustomTable,
            // EditScannedItem,
            BModal,
            BButton
        },
        data() {
            return {
                modalActive: false,
                scannedItems: [],
                edit:false,
                selectedItem:null,
                stockTakingId: ''
            }
        },
        computed:{
            money_types() {
                return getMoneyTypes()
            },
            weight_types() {
                return getWeightTypes()
            },
            volume_types() {
                return getVolumeTypes()
            },
            piece_types() {
                return getPieceTypes()
            },
            columnDefs() {

                const defs = [
                    { headerName: this.$t('table_fields.name'), field: 'ingredient.name', filter: true, editable: false},
                    { headerName: this.$t('table_fields.code'), field: 'ingredient.ingredient_code', filter: false, editable: false},
                    { headerName: this.$t('table_fields.stock'), field: 'stock', filter: false, editable: true, cellRenderer: (params) => this.getQuantityValue(params.data), cellEditorFramework: 'packagingEditor'},
                    { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer'}
                ]


                return defs
            },
            fields() {
                const defs = [
                    {
                        key: 'ingredient.name',
                        label: this.$t('table_fields.name'),
                        filter: true,
                        filterType: 'input',
                        permissions: true,
                        minWidth: 110
                    },
                    {
                        key: 'ingredient.ingredient_code',
                        label: this.$t('table_fields.code'),
                        filter: true,
                        filterType: 'input',
                        permissions: true,
                        minWidth: 100
                    },
                    {
                        key: 'stock',
                        label: this.$t('table_fields.stock'),
                        filter: false,
                        permissions: true,
                        editReturnParam: 'ingredient_id',
                        formatterItem: this.formatStock,
                        editable: true,
                        minWidth: 140
                    },
                    {
                        key: 'actions',
                        label: this.$t('table_fields.actions'),
                        permissions: true
                    }
                ]
                return defs
            }
        },
        methods: {
            formatStock(payload) {
                return this.getStock(payload.stock, payload.ingredient.packaging_unit)
            },
            editStock(payload) {
                const index = this.scannedItems.findIndex(ele => ele.ingredient_id === payload.item_id)
                if (index >= 0) {
                    const item = this.scannedItems[index]
                    item.stock = this.convertToDefaultStock(payload.value, item.ingredient.packaging_unit)
                    // Vue.set(this.scannedItems[index], 'stock', newStock)
                    this.editScannedItem(item)
                }
            },
            getQuantityValue(data) {
                return this.getStock(data.stock, data.ingredient.packaging_unit)
            },
            getStock(stock, unit) {
                const stockOut = this.getStockNumber(stock, unit)

                return `${stockOut} ${unit}`
            },
            getStockNumber(stock, unit) {
                let type = null

                if (unit === 'mL' || unit === 'dL' || unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === unit)
                } else if (unit === 'g' || unit === 'dag' || unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === unit)
                } else if (unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === unit)
                }

                if (!type) {
                    return
                }

                const allowedDecimals = Math.log10(type.factor)

                const stockOut = Number(Number(stock) / Number(type.factor)).toFixed(allowedDecimals)

                return stockOut
            },
            convertToDefaultStock(packaging, unit) {
                let type = null
                if (unit === 'mL' || unit === 'dL' || unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === unit)
                } else if (unit === 'g' || unit === 'dag' || unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === unit)
                } else if (unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === unit)
                }

                if (!type) {
                    return 0
                }

                const stock = Number(Number(packaging) * Number(type.factor)).toFixed(2).replace('.00', '')
                return Number(stock)
            },
            open(data, stock_taking_id) {
                this.scannedItems = data
                this.stockTakingId = stock_taking_id
                this.loadScannedItems()
                this.modalActive = true
            },
            editItem(item) {
                this.edit = true
                this.selectedItem = item
                this.$refs.editScannedItem.setData(item)
            },
            async editScannedItem(data) {
                this.edit = false

                try {
                    data.stock = Number(data.stock)
                    await this.$http.put(`/api/client/v1/stock_takings/scanned/${data.id}`, data)
                    this.$printSuccess(this.$t('print.success.edit'))
                    await this.loadScannedItems()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            async deleteItem(id) {
                this.edit = false

                try {
                    await this.$http.delete(`/api/client/v1/stock_takings/scanned/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadScannedItems()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            },
            async acquire() {
                try {
                    await this.$http.put(`/api/client/v1/stock_takings/${this.stockTakingId}/acquire`, {})
                    this.$printSuccess(this.$t('print.success.acquire'))
                    this.modalActive = false
                    this.$emit('acquired')
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.acquire'))
                }
            },
            async loadScannedItems() {
                try {
                    const res = await this.$http.get(`/api/client/v1/stock_takings/${this.$route.params.id}/scanned_items`)
                    this.scannedItems = res.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            }
        }
    }
</script>