<template>
    <add-stock-taking ref="editStockTaking" :editing="true" @acquired="acquired"/>
</template>

<script>
    import AddStockTaking from '@/views/StockTaking/AddStockTaking.vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            AddStockTaking
        },
        data() {
            return {
                stockTaking: null,
                stockTakingId: '',
                stockTakingTemporary: null,
                view: false,
                editing: false
            }
        },
        methods:{
            async acquired() {
                await this.loadDataEditing()
                await this.$refs.editStockTaking.openEditing(this.stockTakingTemporary, this.stockTaking, this.editing)
            },
            async loadDataEditing() {
                try {
                    const res1 = this.$http.get(`/api/client/v1/stock_takings_temporary/${this.stockTakingId}/`)
                    const res2 = this.$http.get(`/api/client/v1/stock_takings/${this.stockTakingId}/`)
                    
                    const responses = await Promise.all([res1, res2])
                    this.stockTakingTemporary = responses[0].data ?? {}
                    this.stockTaking = responses[1].data.stock_taking ?? {}
                    this.editing = responses[1].data.exists

                    this.stockTaking.timestamp = this.dayjs(this.stockTaking.timestamp).toISOString()
                    this.stockTakingTemporary.timestamp = this.dayjs(this.stockTakingTemporary.timestamp).toISOString()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            }
        },
        async mounted() {
            this.stockTakingId = this.$route.params.id
            await this.loadDataEditing()
            await this.$refs.editStockTaking.openEditing(this.stockTakingTemporary, this.stockTaking, this.editing)

        }
    }
</script>